// Get the chat shadow root element
const getShadowRootElement = () =>
	document.querySelector(
		"div#bc-livechat-container div#bc-frame-container bestchat-inbox-root"
	);
// Get the chat container inside shadow root element
const getChatContainer = () =>
	getShadowRootElement()?.shadowRoot?.getElementById("bc-livechat-root");

const hideChat = (chatContainer: HTMLElement): void => {
	chatContainer.style.display = "none";
};

const showChat = (chatContainer: HTMLElement): void => {
	chatContainer.style.display = "block";
};

const handleChatClose = (): void => {
	const chatContainer = getChatContainer();
	if (chatContainer) {
		requestAnimationFrame(() => {
			hideChat(chatContainer);
		});
	}
};

// Utility function to find element from event path base on matching conditions
const findElementInEventPath = (
	event: Event,
	matchCondition: (el: HTMLElement) => boolean
): HTMLElement | null => {
	const path = event.composedPath() as HTMLElement[];
	return path.find(el => matchCondition(el)) || null;
};

const handleShadowRootElementClickEvent = (event: Event): void => {
	const closeElement = findElementInEventPath(event, el =>
		el?.className?.match("iconfont iconminimize") && el?.tagName == "A" ? true : false
	);
	if (closeElement) {
		handleChatClose();
		return;
	}
};

// Setup and manage chat behavior
const setupChatIntegrations = (): void => {
	const shadowRootElement = getShadowRootElement();
	const chatContainer = getChatContainer();
	if (!shadowRootElement || !chatContainer) {
		requestAnimationFrame(setupChatIntegrations);
		return;
	}
	// Initially chat will be hidden
	requestAnimationFrame(() => {
		hideChat(chatContainer);
	});
	// Attach the click event handler for track the click event
	shadowRootElement.addEventListener("click", handleShadowRootElementClickEvent);
};

// Exposed utility to programmatically open chat
export const openChat = (onCloseCallback?: () => void): void => {
	const chatContainer = getChatContainer();

	if (!chatContainer) {
		console.warn("Chat container not found");
		return;
	}

	// Trigger click on chat open button
	const chatButton = chatContainer.querySelector(
		".chat-icon-icon"
	) as unknown as HTMLElement | null;
	if (chatButton) {
		chatButton.click();
		requestAnimationFrame(() => {
			// Make the chat visiable on screen
			showChat(chatContainer);
		});
	}

	if (onCloseCallback && typeof onCloseCallback === "function") {
		onCloseCallback();
	}
};

// Initialize chat functionalities
export const initializeChat = (): void => {
	setupChatIntegrations();
};
