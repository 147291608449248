import {
	openChat as openChatFB,
	initializeChat as initializeChatFB
} from "./chatHandlers/facebookHandler";
import {
	openChat as openChatZopim,
	initializeChat as initializeChatZopim
} from "./chatHandlers/zopimHandler";
import {
	openChat as openChatTawk,
	initializeChat as initializeChatTawk
} from "./chatHandlers/tawkHandler";
import {
	openChat as openChatLC,
	initializeChat as initializeChatLC
} from "./chatHandlers/lcHandler";
import {
	openChat as openChatFC,
	initializeChat as initializeChatFC
} from "./chatHandlers/fcHandler";
import {
	openChat as openChatTIDIO,
	initializeChat as initializeChatTIDIO
} from "./chatHandlers/tidioHandler";
import {
	openChat as openChatCrisp,
	initializeChat as initializeChatCrisp
} from "./chatHandlers/crispHandler";
import {
	openChat as openChatSmartsupp,
	initializeChat as initializeChatSmartsupp
} from "./chatHandlers/smartsuppHandler";

import {
	openChat as openChatOlark,
	initializeChat as initializeChatOlark
} from "./chatHandlers/olarkHandler";
import {
	openChat as openChatZendesk,
	initializeChat as initializeChatZendesk
} from "./chatHandlers/zendeskHandler";

import {
	openChat as openChatSc,
	initializeChat as initializeChatSc
} from "./chatHandlers/scHanlder";
import {
	openChat as openChatZendeskClassic,
	initializeChat as initializeZendeskClassic
} from "./chatHandlers/zendeskClassicHandler";

import {
	openChat as openShopifyChat,
	initializeChat as initializeShopifyChat
} from "./chatHandlers/shopifyChat";
import {
	openChat as openChatraChat,
	initializeChat as initializeChatraChat
} from "./chatHandlers/chatraHandler";

import {
	openChat as openBestChat,
	initializeChat as initializeBestChat
} from "./chatHandlers/bestChatHandler";

import { IChatHandler, IChatType } from "../types/sdk";

export const chatHandlers: { [key in IChatType]: IChatHandler } = {
	fb: {
		// fb = Facebook chat
		openChat: openChatFB,
		initializeChat: initializeChatFB
	},
	zopim: {
		openChat: openChatZopim,
		initializeChat: initializeChatZopim
	},
	shopify: {
		openChat: openShopifyChat,
		initializeChat: initializeShopifyChat
	},
	chatra: {
		openChat: openChatraChat,
		initializeChat: initializeChatraChat
	},
	zendesk: {
		openChat: openChatZendesk,
		initializeChat: initializeChatZendesk
	},
	sc: {
		// sc = Zendesh sunshine coversation
		openChat: openChatSc,
		initializeChat: initializeChatSc
	},
	tawk: {
		openChat: openChatTawk,
		initializeChat: initializeChatTawk
	},
	lc: {
		// lc = Live chat
		openChat: openChatLC,
		initializeChat: initializeChatLC
	},
	fc: {
		//fc = Fresh chat
		openChat: openChatFC,
		initializeChat: initializeChatFC
	},
	crisp: {
		openChat: openChatCrisp,
		initializeChat: initializeChatCrisp
	},
	tidio: {
		openChat: openChatTIDIO,
		initializeChat: initializeChatTIDIO
	},
	smartsupp: {
		openChat: openChatSmartsupp,
		initializeChat: initializeChatSmartsupp
	},
	olark: {
		openChat: openChatOlark,
		initializeChat: initializeChatOlark
	},
	zendeskClassic: {
		openChat: openChatZendeskClassic,
		initializeChat: initializeZendeskClassic
	},
	bestChat: {
		openChat: openBestChat,
		initializeChat: initializeBestChat
	}
};
